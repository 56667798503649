<template>
  <ActionGeneric :to="to" label="Visualizza" iconClass="fas fa-eye" :eventName="eventName" :btnClasses="btnClasses" :btnClassesMobile="btnClassesMobile" :disabled="disabled" />
</template>

<script>

import actionMixin from './mixins/action';

export default {
  name: 'action-show',
  mixins: [actionMixin],
  components: {
    ActionGeneric: () => import('./ActionGeneric'),
  },
};

</script>
